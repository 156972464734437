import { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  Radio,
  Space,
  message,
  Badge
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import styles from './business_justification.module.less';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
import { debounce } from 'lodash';
import moment from 'moment';
import { history } from 'app/history';
import SelectRationales_DynamicInput from './SelectRationales_DynamicInput';
import GlobalFinancialPerformanceMetrics_table from '@/common/BJ_Tables/globalFinancialPerformanceMetrics/GlobalFinancialPerformanceMetrics_table';
import BusinessJustificationUS_BaseCase from '@/common/BJ_Tables/BJ_US_BaseCase/BusinessJustificationUS_BaseCase';
import BusinessJustificationUS_BestCase from '@/common/BJ_Tables/BJ_US_BestCase/BusinessJustificationUS_BestCase';
import FinancialPerformanceMetricsUS from '@/common/BJ_Tables/FPM_US/FinancialPerformanceMetricsUS';
import BusinessJustification_EU_BaseCase from '@/common/BJ_Tables/BJ_EU_BaseCase/BusinessJustification_EU_BaseCase';
import BusinessJustification_EU_BestCase from '@/common/BJ_Tables/BJ_EU_BestCase/BusinessJustification_EU_BestCase';
import FinancialPerformanceMetrics_EU from '@/common/BJ_Tables/FPM_EU/FinancialPerformanceMetrics_EU';
import BusinessJustification_RoW_BaseCase from '@/common/BJ_Tables/BJ_RoW_BaseCase/BusinessJustification_RoW_BaseCase';
import BusinessJustification_RoW_BestCase from '@/common/BJ_Tables/BJ_RoW_BestCase/BusinessJustification_RoW_BestCase';
import FinancialPerformanceMetrics_RoW from '@/common/BJ_Tables/FPM_RoW/FinancialPerformanceMetrics_RoW';
import { dropDown_bjc_forecast_items } from '../../redux/slice';
import { hasEmptyValue } from 'utilities/helpers';

const { Option } = Select;
// const { TextArea } = Input;
const { Panel } = Collapse;

const filters = [
  {
    id: 1,
    name: 'BC-US',
    value: 'BJ_US'
  },
  {
    id: 2,
    name: 'BC-EU',
    value: 'BJ_EU'
  },
  {
    id: 3,
    name: 'BC-RoW',
    value: 'BJ_RoW'
  }
];

const Business_justification = ({
  PIF_form_id,
  setSelectedTab,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  dropDown_budget_itemsData,
  createUpdateBJ_form,
  createUpdateBJ_formLoading,
  dropDown_fy_mastersData,
  //dropDown_bjc_forecast_itemsData,
  //debounceBjc_forecast_items,
  dropDown_fpm_itemsData,
  debounceFpm_items,
  dropDown_gfpm_itemsData,
  debounceGfpm_items,
  dropDown_product_ratingData,
  debounceProduct_rating
}) => {
  if (!completedTabs?.includes(6)) {
    setCompletedTabs([...completedTabs, 6]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(6);

  const bj_edit = localStorage.getItem('Edit_PIF_form_start');
  // const storedData_sr = localStorage.getItem('BJ_SelectionRationale');
  // const storedData_gfpm = localStorage.getItem('BJ_GlobalFPM');
  //const storedData_US_BaseCase = localStorage.getItem('BJ_US_BaseCase');
  //const storedData_US_BestCase = localStorage.getItem('BJ_US_BestCase');
  // const storedData_fpm_us = localStorage.getItem('BJ_FPM_US');

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [formData1, setFormData1] = useState({});
  const [fields, setFields] = useState();
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [dataSource_GFPM, setDataSource_GFPM] = useState();
  const [dataSource_US_BestCase, setDataSource_US_BestCase] = useState();
  const [dataSource_US_BaseCase, setDataSource_US_BaseCase] = useState();
  const [dataSource_FPM_US, setDataSource_FPM_US] = useState();
  const [selectedYear_US_BaseCase, setSelectedYear_US_BaseCase] = useState(1);
  const [selectedYear_US_BestCase, setSelectedYear_US_BestCase] = useState(1);

  const [dataSource_EU_BestCase, setDataSource_EU_BestCase] = useState();
  const [dataSource_EU_BaseCase, setDataSource_EU_BaseCase] = useState();
  const [dataSource_FPM_EU, setDataSource_FPM_EU] = useState();
  const [selectedYear_EU_BaseCase, setSelectedYear_EU_BaseCase] = useState(1);
  const [selectedYear_EU_BestCase, setSelectedYear_EU_BestCase] = useState(1);

  const [dataSource_RoW_BestCase, setDataSource_RoW_BestCase] = useState();
  const [dataSource_RoW_BaseCase, setDataSource_RoW_BaseCase] = useState();
  const [dataSource_FPM_RoW, setDataSource_FPM_RoW] = useState();
  const [selectedYear_RoW_BaseCase, setSelectedYear_RoW_BaseCase] = useState(1);
  const [selectedYear_RoW_BestCase, setSelectedYear_RoW_BestCase] = useState(1);

  const [USTableError, setUSTableError] = useState({
    base: false,
    best: false
  });
  const [EUTableError, setEUTableError] = useState({
    base: false,
    best: false
  });
  const [RoWTableError, setRoWTableError] = useState({
    base: false,
    best: false
  });

  const [bjc_forecast_items, setBjc_forecast_items] = useState('');

  const { dropDown_bjc_forecast_itemsData } = useSelector(
    (state) => state.pif_fd_form
  );

  useEffect(() => {
    let payload_bjc_forecast_items = {
      pif_id: PIF_form_id,
      model_name: 'units_and_others',
      type: 'bjc_forecast_items',
      page: 1,
      per_page: '100'
    };
    if (bjc_forecast_items)
      payload_bjc_forecast_items['search'] = bjc_forecast_items;
    dispatch(dropDown_bjc_forecast_items(payload_bjc_forecast_items));
  }, [bjc_forecast_items, getFormDetailsByIdData]);

  const debounceBjc_forecast_items = useMemo(() => {
    const loadOptions = (bjc_forecast_items) => {
      setBjc_forecast_items(bjc_forecast_items);
    };
    return debounce(loadOptions, 500);
  }, []);

  const transformData = (rawData) => {
    return rawData.map((item, index) => {
      const transformedItem = {
        key: index.toString(),
        units_and_others_id: item.units_and_others_id,
        cummulative: item.cummulative,
        ...item.BJCF_FY_wise_values.reduce((acc, fyItem) => {
          acc[fyItem.fy_master_id] = fyItem.value;
          return acc;
        }, {})
      };
      return transformedItem;
    });
  };

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  console.log('selectedTabId@@:', selectedTabId);

  //----BJ_SelectionRationale-------
  function generateKey_sr() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.selection_rationale
        ?.length === 0
    ) {
      setFields([
        {
          key: generateKey_sr(),
          item: ''
          //is_active: true
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.selection_rationale
        ?.length > 0 &&
      bj_edit === 'true'
    ) {
      const newData =
        getFormDetailsByIdData?.business_justification?.selection_rationale?.map(
          (itm) => ({
            key: itm?.id?.toString(),
            id: itm?.id,
            item: itm?.item
            //is_active: itm?.//is_active
          })
        );
      setFields(newData);
    }
  }, [getFormDetailsByIdData, bj_edit === 'true']);

  //----BJ_GlobalFPM_table-------
  function generateKey_gfpm() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.GFPM?.length === 0
    ) {
      setDataSource_GFPM([
        {
          key: generateKey_gfpm(),
          name: '',
          value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.GFPM?.length > 0 &&
      bj_edit === 'true'
    ) {
      const newData = getFormDetailsByIdData?.business_justification?.GFPM?.map(
        (item) => ({
          key: item.id.toString(),
          //id: item.id,
          name: item.name,
          value: item.value
          //is_active: item.//is_active
        })
      );
      setDataSource_GFPM(newData);
    }
  }, [getFormDetailsByIdData, bj_edit === 'true']);

  //----BJ_FPM_US_table-------
  function generateKey_fpm_us() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions[0]?.FPMs
          ?.length === 0) ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions?.length ===
          0)
    ) {
      console.log('bj_edit_FALSE_US:::', bj_edit);
      setDataSource_FPM_US([
        {
          key: generateKey_fpm_us(),
          units_and_others_id: '',
          best_case_value: '',
          base_case_value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [bj_edit === 'false', getFormDetailsByIdData]);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions?.length > 0 &&
      getFormDetailsByIdData?.business_justification?.bj_regions[0]?.FPMs
        ?.length > 0 &&
      bj_edit === 'true'
    ) {
      console.log('bj_edit_TRUE_US:::', bj_edit);
      const newData =
        getFormDetailsByIdData?.business_justification?.bj_regions[0]?.FPMs?.map(
          (item) => ({
            key: item.id.toString(),
            //id: item.id,
            // bj_region_id: item.bj_region_id,
            units_and_others_id: item.units_and_others_id,
            best_case_value: item.best_case_value,
            base_case_value: item.base_case_value
            //is_active: item.//is_active
          })
        );
      setDataSource_FPM_US(newData);
    }
  }, [getFormDetailsByIdData, bj_edit === 'true']);

  //----BJ_US_BaseCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[0]
        ?.bj_base_case?.length === 0
    ) {
      setDataSource_US_BaseCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: [] //units_and_others_id/
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[0]
        ?.bj_base_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      // const newData =
      //   getFormDetailsByIdData?.business_justification?.bj_regions[0]?.bj_base_case?.BJC_forecasts?.map(
      //     (item) => ({
      //       key: '0',
      //       id: item?.id,
      //       bj_base_case_id: item?.bj_base_case_id,
      //       units_and_others_id: item?.units_and_others_id,
      //       cummulative: item?.cummulative,
      //       FY_wise_values: item?.BJCF_FY_wise_values?.map((itm) => ({
      //         id: itm?.id,
      //         BJC_forecast_id: itm?.BJC_forecast_id,
      //         year: itm?.fy_master_id,
      //         value: itm?.value,
      //         //is_active: itm?.//is_active
      //       })),

      //       //is_active: item?.//is_active
      //     })
      //   );
      // setDataSource_US_BaseCase(newData);

      setSelectedYear_US_BaseCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[0]
          ?.bj_base_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BaseCase_US = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[0]
          ?.bj_base_case?.BJC_forecasts
      );

      setDataSource_US_BaseCase(BJ_Data_BaseCase_US);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_US_BaseCase */
  ]);
  useEffect(() => {
    setDataSource_US_BaseCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_US_BaseCase]);

  const BJ_Data_BaseCase_US = dataSource_US_BaseCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key); //--------------------------
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value); //--------------------------
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });
  // console.log('dataSource_US_BaseCase:::', dataSource_US_BaseCase);
  // console.log('BJ_Data_BaseCase_US:::', BJ_Data_BaseCase_US);
  // console.log('selectedYear_US_BaseCase:::', selectedYear_US_BaseCase);

  //----BJ_US_BestCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[0]
        ?.bj_best_case?.length === 0
    ) {
      setDataSource_US_BestCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: []
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[0]
        ?.bj_best_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      // const newData =
      //   getFormDetailsByIdData?.business_justification?.bj_regions[0]?.bj_best_case?.BJC_forecasts?.map(
      //     (item) => ({
      //       key: '0',
      //       id: item?.id,
      //       bj_best_case_id: item?.bj_best_case_id,
      //       units_and_others_id: item?.units_and_others_id,
      //       cummulative: item?.cummulative,
      //       FY_wise_values: item?.BJCF_FY_wise_values?.map((itm) => ({
      //         id: itm?.id,
      //         BJC_forecast_id: itm?.BJC_forecast_id,
      //         year: itm?.fy_master_id,
      //         value: itm?.value,
      //         //is_active: itm?.//is_active
      //       })),

      //       //is_active: item?.//is_active
      //     })
      //   );
      setSelectedYear_US_BestCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[0]
          ?.bj_best_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BestCase_US = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[0]
          ?.bj_best_case?.BJC_forecasts
      );
      setDataSource_US_BestCase(BJ_Data_BestCase_US);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_US_BestCase */
  ]);
  useEffect(() => {
    setDataSource_US_BestCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_US_BestCase]);

  const BJ_Data_BestCase_US = dataSource_US_BestCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key);
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value);
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });
  //console.log('BJ_Data_BestCase_US:::', BJ_Data_BestCase_US);

  //----BJ_FPM_EU_table-------
  function generateKey_fpm_eu() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions[1]?.FPMs
          ?.length === 0) ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions?.length ===
          0) ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions?.length < 2)
    ) {
      setDataSource_FPM_EU([
        {
          key: generateKey_fpm_eu(),
          units_and_others_id: '',
          best_case_value: '',
          base_case_value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions?.length > 0 &&
      getFormDetailsByIdData?.business_justification?.bj_regions[1]?.FPMs
        ?.length > 0 &&
      bj_edit === 'true'
    ) {
      const newData =
        getFormDetailsByIdData?.business_justification?.bj_regions[1]?.FPMs?.map(
          (item) => ({
            key: item.id.toString(),
            //id: item.id,
            // bj_region_id: item.bj_region_id,
            units_and_others_id: item.units_and_others_id,
            best_case_value: item.best_case_value,
            base_case_value: item.base_case_value
            //is_active: item.//is_active
          })
        );
      setDataSource_FPM_EU(newData);
    }
  }, [getFormDetailsByIdData, bj_edit === 'true']);

  //----BJ_EU_BaseCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[1]
        ?.bj_base_case?.length === 0
    ) {
      setDataSource_EU_BaseCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: [] //units_and_others_id/
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[1]
        ?.bj_base_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      setSelectedYear_EU_BaseCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[1]
          ?.bj_base_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BaseCase_EU = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[1]
          ?.bj_base_case?.BJC_forecasts
      );

      setDataSource_EU_BaseCase(BJ_Data_BaseCase_EU);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_EU_BaseCase */
  ]);
  useEffect(() => {
    setDataSource_EU_BaseCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_EU_BaseCase]);

  const BJ_Data_BaseCase_EU = dataSource_EU_BaseCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key);
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value);
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });

  //----BJ_EU_BestCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[1]
        ?.bj_best_case?.length === 0
    ) {
      setDataSource_EU_BestCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: []
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[1]
        ?.bj_best_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      setSelectedYear_EU_BestCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[1]
          ?.bj_best_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BestCase_EU = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[1]
          ?.bj_best_case?.BJC_forecasts
      );
      setDataSource_EU_BestCase(BJ_Data_BestCase_EU);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_EU_BestCase */
  ]);
  useEffect(() => {
    setDataSource_EU_BestCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_EU_BestCase]);

  const BJ_Data_BestCase_EU = dataSource_EU_BestCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key);
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value);
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });

  //----BJ_FPM_RoW_table-------
  function generateKey_fpm_row() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    //console.log('bj_edit_FALSE:::', bj_edit);
    console.log(
      'business_justification?.length:::',
      getFormDetailsByIdData?.business_justification?.length
    );
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions[2]?.FPMs
          ?.length === 0) ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions?.length ===
          0) ||
      (bj_edit === 'true' &&
        getFormDetailsByIdData?.business_justification?.bj_regions?.length < 3)
    ) {
      setDataSource_FPM_RoW([
        {
          key: generateKey_fpm_row(),
          units_and_others_id: '',
          best_case_value: '',
          base_case_value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);

  useEffect(() => {
    //console.log('bj_edit_TRUE1111:::', bj_edit);
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions?.length > 0 &&
      getFormDetailsByIdData?.business_justification?.bj_regions[2]?.FPMs
        ?.length > 0 &&
      bj_edit === 'true'
    ) {
      //console.log('bj_edit_TRUE2222:::', bj_edit);
      const newData =
        getFormDetailsByIdData?.business_justification?.bj_regions[2]?.FPMs?.map(
          (item) => ({
            key: item.id.toString(),
            // id: item.id,
            // bj_region_id: item.bj_region_id,
            units_and_others_id: item.units_and_others_id,
            best_case_value: item.best_case_value,
            base_case_value: item.base_case_value
            //is_active: item.//is_active
          })
        );
      setDataSource_FPM_RoW(newData);
    }
  }, [getFormDetailsByIdData, bj_edit === 'true']);
  //----BJ_RoW_BaseCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[2]
        ?.bj_base_case?.length === 0
    ) {
      setDataSource_RoW_BaseCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: [] //units_and_others_id/
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[2]
        ?.bj_base_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      setSelectedYear_RoW_BaseCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[2]
          ?.bj_base_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BaseCase_RoW = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[2]
          ?.bj_base_case?.BJC_forecasts
      );

      setDataSource_RoW_BaseCase(BJ_Data_BaseCase_RoW);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_RoW_BaseCase */
  ]);
  useEffect(() => {
    setDataSource_RoW_BaseCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_RoW_BaseCase]);

  const BJ_Data_BaseCase_RoW = dataSource_RoW_BaseCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key);
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value);
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });

  //----BJ_RoW_BestCase_table-------
  useEffect(() => {
    if (
      bj_edit === 'false' ||
      getFormDetailsByIdData?.business_justification === null ||
      getFormDetailsByIdData?.business_justification?.bj_regions[2]
        ?.bj_best_case?.length === 0
    ) {
      setDataSource_RoW_BestCase([
        {
          key: '0',
          units_and_others_id: '',
          cummulative: '',
          FY_wise_values: []
        }
      ]);
    }
  }, [getFormDetailsByIdData, bj_edit === 'false']);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.business_justification?.bj_regions[2]
        ?.bj_best_case?.BJC_forecasts?.length > 0 &&
      bj_edit === 'true'
    ) {
      setSelectedYear_RoW_BestCase(
        getFormDetailsByIdData?.business_justification?.bj_regions[2]
          ?.bj_best_case?.BJC_forecasts[0]?.BJCF_FY_wise_values?.[0]
          ?.fy_master_id
      );
      const BJ_Data_BestCase_RoW = transformData(
        getFormDetailsByIdData?.business_justification?.bj_regions[2]
          ?.bj_best_case?.BJC_forecasts
      );
      setDataSource_RoW_BestCase(BJ_Data_BestCase_RoW);
    }
  }, [
    getFormDetailsByIdData,
    bj_edit === 'true' /* , selectedYear_RoW_BestCase */
  ]);
  useEffect(() => {
    setDataSource_RoW_BestCase([
      { key: '0', units_and_others_id: '', cummulative: '', FY_wise_values: [] }
    ]);
  }, [selectedYear_RoW_BestCase]);

  const BJ_Data_BestCase_RoW = dataSource_RoW_BestCase?.map((row) => {
    const { key, units_and_others_id, cummulative, ...rest } = row;
    console.log('key:::', key);
    const FY_wise_values = Object.entries(rest)
      .filter(([year, value]) => {
        console.log('value:::', value);
        return year !== 'FY_wise_values';
      }) // Filter out unwanted entries
      .map(([year, value]) => ({
        fy_master_id: year,
        value: value
      }));
    return {
      units_and_others_id: units_and_others_id,
      cummulative: cummulative,
      FY_wise_values: FY_wise_values
    };
  });

  useEffect(() => {
    form.setFieldsValue({
      product_rating_id:
        getFormDetailsByIdData?.business_justification?.product_rating?.id,
      Remarks: getFormDetailsByIdData?.business_justification?.bj_remarks
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    //console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };
  // console.log('BJ_Data_BaseCase_US:::', BJ_Data_BaseCase_US);
  // console.log('BJ_Data_BaseCase_EU:::', BJ_Data_BaseCase_EU);
  // console.log('BJ_Data_BaseCase_RoW:::', BJ_Data_BaseCase_RoW);

  // else if (
  //   dataSource_EU_BaseCase.length < 1 ||
  //   hasEmptyValue(dataSource_EU_BaseCase)
  // ) {
  //   message.error(
  //     'Business Case EU - Base Case table must have at least 1 entry'
  //   );
  //   setUSTableError({ ...USTableError, best: false, base: false });
  //   setEUTableError({ ...EUTableError, base: true });
  //   setSelectedTabId(2);
  //   return;
  // } else if (
  //   dataSource_EU_BestCase.length < 1 ||
  //   hasEmptyValue(dataSource_EU_BestCase)
  // ) {
  //   message.error(
  //     'Business Case EU - Best Case table must have at least 1 entry'
  //   );
  //   setUSTableError({ ...USTableError, best: false, base: false });
  //   setEUTableError({ ...EUTableError, best: true, base: false });
  //   setSelectedTabId(2);
  //   return;
  // } else if (
  //   dataSource_RoW_BaseCase.length < 1 ||
  //   hasEmptyValue(dataSource_RoW_BaseCase)
  // ) {
  //   message.error(
  //     'Business Case RoW - Base Case table must have at least 1 entry'
  //   );
  //   setUSTableError({ ...USTableError, best: false, base: false });
  //   setEUTableError({ ...EUTableError, best: false, base: false });
  //   setRoWTableError({ ...RoWTableError, base: true });
  //   setSelectedTabId(3);
  //   return;
  // } else if (
  //   dataSource_RoW_BestCase.length < 1 ||
  //   hasEmptyValue(dataSource_RoW_BestCase)
  // ) {
  //   message.error(
  //     'Business Case RoW - Base Case table must have at least 1 entry'
  //   );
  //   setUSTableError({ ...USTableError, best: false, base: false });
  //   setEUTableError({ ...EUTableError, best: false, base: false });
  //   setRoWTableError({ ...RoWTableError, base: false, best: true });
  //   setSelectedTabId(3);
  //   return;
  // }

  const onFinish = () => {
    if (
      dataSource_US_BaseCase.length < 1 ||
      hasEmptyValue(dataSource_US_BaseCase, ['FY_wise_values'])
    ) {
      message.error(
        'Business Case US - Base Case table must have at least 1 entry'
      );
      setUSTableError({ ...USTableError, base: true });
      // setSelectedTabId(1);
      return;
    } else if (
      dataSource_US_BestCase.length < 1 ||
      hasEmptyValue(dataSource_US_BestCase, ['FY_wise_values'])
    ) {
      message.error(
        'Business Case US - Best Case table must have at least 1 entry'
      );
      setUSTableError({ ...USTableError, best: true, base: false });
      // setSelectedTabId(1);
      return;
    } else {
      setUSTableError({ ...USTableError, best: false, base: false });
      setEUTableError({ ...EUTableError, best: false, base: false });
      // setRoWTableError({ ...RoWTableError, base: false, best: false });

      const base_case_data1 =
        BJ_Data_BaseCase_US[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BaseCase_US;

      const best_case_data1 =
        BJ_Data_BestCase_US[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BestCase_US;

      const fpm_data1 = dataSource_FPM_US
        ? dataSource_FPM_US[0]?.units_and_others_id === ''
          ? []
          : dataSource_FPM_US
        : [];

      const base_case_data2 =
        BJ_Data_BaseCase_EU[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BaseCase_EU;

      const best_case_data2 =
        BJ_Data_BestCase_EU[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BestCase_EU;

      const fpm_data2 = dataSource_FPM_EU
        ? dataSource_FPM_EU[0]?.units_and_others_id === ''
          ? []
          : dataSource_FPM_EU
        : [];

      const base_case_data3 =
        BJ_Data_BaseCase_RoW[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BaseCase_RoW;

      const best_case_data3 =
        BJ_Data_BestCase_RoW[0]?.units_and_others_id === ''
          ? []
          : BJ_Data_BestCase_RoW;

      const fpm_data3 = dataSource_FPM_RoW
        ? dataSource_FPM_RoW[0]?.units_and_others_id === ''
          ? []
          : dataSource_FPM_RoW
        : [];

      const payload = {
        edit_type: 'mandatory',
        pif_id: PIF_form_id,
        selection_rationale_list:
          fields[0]?.item === ''
            ? []
            : fields?.map((obj) => ({
                ...obj
              })),
        product_rating_id:
          formData1?.product_rating_id === undefined
            ? getFormDetailsByIdData?.business_justification?.product_rating?.id
            : formData1?.product_rating_id,
        bj_regions:
          base_case_data1?.length === 0 &&
          best_case_data1?.length === 0 &&
          fpm_data1?.length === 0 &&
          base_case_data2?.length === 0 &&
          best_case_data2?.length === 0 &&
          fpm_data2?.length === 0 &&
          base_case_data3?.length === 0 &&
          best_case_data3?.length === 0 &&
          fpm_data3?.length === 0
            ? []
            : (base_case_data1?.length > 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0) ||
              (best_case_data1?.length > 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0) ||
              (fpm_data1?.length > 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0)
            ? [
                {
                  country_id: 1,
                  base_case:
                    BJ_Data_BaseCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_US
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_US
                        }
                      : [],

                  fpm:
                    dataSource_FPM_US[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_US?.map((obj) => ({
                          ...obj
                        }))
                }
              ]
            : (base_case_data2?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0) ||
              (best_case_data2?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0) ||
              (fpm_data2?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data3?.length === 0 &&
                best_case_data3?.length === 0 &&
                fpm_data3?.length === 0)
            ? [
                {
                  country_id: 2,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_EU
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_EU
                        }
                      : [],

                  fpm:
                    dataSource_FPM_EU[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_EU?.map((obj) => ({
                          ...obj
                        }))
                }
              ]
            : (base_case_data3?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0) ||
              (best_case_data3?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0) ||
              (fpm_data3?.length > 0 &&
                base_case_data1?.length === 0 &&
                best_case_data1?.length === 0 &&
                fpm_data1?.length === 0 &&
                base_case_data2?.length === 0 &&
                best_case_data2?.length === 0 &&
                fpm_data2?.length === 0)
            ? [
                {
                  country_id: 3,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_RoW
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_RoW
                        }
                      : [],

                  fpm:
                    dataSource_FPM_RoW[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_RoW?.map((obj) => ({
                          ...obj
                        }))
                }
              ]
            : base_case_data1?.length > 0 &&
              base_case_data2?.length > 0 &&
              base_case_data3?.length === 0
            ? [
                {
                  country_id: 1,
                  base_case:
                    BJ_Data_BaseCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_US
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_US
                        }
                      : [],
                  //  {
                  //   BJCFs: BJ_Data_BestCase_US
                  // },

                  fpm:
                    dataSource_FPM_US[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_US?.map((obj) => ({
                          ...obj
                        }))
                },
                {
                  country_id: 2,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_EU
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_EU
                        }
                      : [],

                  fpm: dataSource_FPM_EU
                    ? dataSource_FPM_EU[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_EU?.map((obj) => ({
                          ...obj
                        }))
                    : []
                }
              ]
            : base_case_data1?.length > 0 &&
              base_case_data2?.length === 0 &&
              base_case_data3?.length > 0
            ? [
                {
                  country_id: 1,
                  base_case:
                    BJ_Data_BaseCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_US
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_US
                        }
                      : [],
                  //  {
                  //   BJCFs: BJ_Data_BestCase_US
                  // },

                  fpm:
                    dataSource_FPM_US[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_US?.map((obj) => ({
                          ...obj
                        }))
                },

                {
                  country_id: 3,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_RoW
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_RoW
                        }
                      : [],

                  fpm:
                    dataSource_FPM_RoW[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_RoW?.map((obj) => ({
                          ...obj
                        }))
                }
              ]
            : base_case_data1?.length === 0 &&
              base_case_data2?.length > 0 &&
              base_case_data3?.length > 0
            ? [
                {
                  country_id: 2,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_EU
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_EU
                        }
                      : [],

                  fpm:
                    dataSource_FPM_EU[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_EU?.map((obj) => ({
                          ...obj
                        }))
                },
                {
                  country_id: 3,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_RoW
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_RoW
                        }
                      : [],

                  fpm:
                    dataSource_FPM_RoW[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_RoW?.map((obj) => ({
                          ...obj
                        }))
                }
              ]
            : [
                {
                  country_id: 1,
                  base_case:
                    BJ_Data_BaseCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_US
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_US[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_US
                        }
                      : [],
                  //  {
                  //   BJCFs: BJ_Data_BestCase_US
                  // },

                  fpm:
                    dataSource_FPM_US[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_US?.map((obj) => ({
                          ...obj
                        }))
                },
                {
                  country_id: 2,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_EU
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_EU[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_EU
                        }
                      : [],

                  fpm:
                    dataSource_FPM_EU[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_EU?.map((obj) => ({
                          ...obj
                        }))
                },
                {
                  country_id: 3,
                  //is_active: true,
                  base_case:
                    BJ_Data_BaseCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BaseCase_RoW
                        }
                      : [],
                  best_case:
                    BJ_Data_BestCase_RoW[0]?.FY_wise_values?.length > 0
                      ? {
                          BJCFs: BJ_Data_BestCase_RoW
                        }
                      : [],

                  fpm:
                    dataSource_FPM_RoW[0]?.units_and_others_id === ''
                      ? []
                      : dataSource_FPM_RoW?.map((obj) => ({
                          ...obj
                        }))
                }
              ],
        GFPM_info:
          dataSource_GFPM?.length &&
          dataSource_GFPM[0].name &&
          dataSource_GFPM[0].value
            ? dataSource_GFPM?.map((obj) => ({
                ...obj
              }))
            : [],
        bj_remarks:
          formData1?.Remarks === undefined
            ? getFormDetailsByIdData?.business_justification?.bj_remarks
            : formData1?.Remarks
      };
      console.log('BS_payload::::', payload);
      console.log('base_case_data111?.length::::', base_case_data1?.length);
      console.log('base_case_data222?.length::::', base_case_data2?.length);
      console.log('base_case_data3333?.length::::', base_case_data3?.length);
      /* [
                  {
                    country_id: 1,
                    base_case:
                      BJ_Data_BaseCase_US[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BaseCase_US
                          }
                        : [],
                    best_case:
                      BJ_Data_BestCase_US[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BestCase_US
                          }
                        : [],
                    //  {
                    //   BJCFs: BJ_Data_BestCase_US
                    // },

                    fpm:
                      dataSource_FPM_US[0]?.units_and_others_id === ''
                        ? []
                        : dataSource_FPM_US?.map((obj) => ({
                            ...obj
                          }))
                  },
                  {
                    country_id: 2,
                    //is_active: true,
                    base_case:
                      BJ_Data_BaseCase_EU[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BaseCase_EU
                          }
                        : [],
                    best_case:
                      BJ_Data_BestCase_EU[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BestCase_EU
                          }
                        : [],

                    fpm:
                      dataSource_FPM_EU[0]?.units_and_others_id === ''
                        ? []
                        : dataSource_FPM_EU?.map((obj) => ({
                            ...obj
                          }))
                  },
                  {
                    country_id: 3,
                    //is_active: true,
                    base_case:
                      BJ_Data_BaseCase_RoW[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BaseCase_RoW
                          }
                        : [],
                    best_case:
                      BJ_Data_BestCase_RoW[0]?.FY_wise_values?.length > 0
                        ? {
                            BJCFs: BJ_Data_BestCase_RoW
                          }
                        : [],

                    fpm:
                      dataSource_FPM_RoW[0]?.units_and_others_id === ''
                        ? []
                        : dataSource_FPM_RoW?.map((obj) => ({
                            ...obj
                          }))
                  }
                ] */
      dispatch(createUpdateBJ_form({ payload }))?.then(function (response) {
        if (response?.payload?.success) {
          //history.push(`/admin-quizzes`);
          //localStorage.setItem('BJ_Edit', true);
          // localStorage.setItem('BJ_SelectionRationale', true);
          // localStorage.setItem('BJ_GlobalFPM', true);
          // localStorage.setItem('BJ_US_BaseCase', true);
          // localStorage.setItem('BJ_US_BestCase', true);
          // localStorage.setItem('BJ_FPM_US', true);
          // localStorage.setItem('BJ_US_BaseCase', true);
          // localStorage.setItem('BJ_US_BestCase', true);

          setSelectedTab(7);
        } else {
          message.error(response?.payload?.message);
          console.log('response?.payload?.error', response?.payload?.error);
        }
      });
    }
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <Row className={styles.pif_page_titel_container}>
          <div className={`${styles.page_title}`}>
            <span className={styles.title_label}>Business Justification</span>
          </div>
          {getFormDetailsByIdData?.changes_in_progress === true && (
            <Badge
              count={'Changes in progress'}
              style={{
                backgroundColor: '#ffffff',
                color: '#0098da',
                textTransform: 'none',
                borderColor: '#0098da',
                fontSize: '12px',
                //fontFamily: 'SFProText Regular',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-20px'
              }}
            />
          )}
        </Row>
        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <SelectRationales_DynamicInput
            fields={fields}
            setFields={setFields}
            bj_edit={bj_edit}
            generateKey_sr={generateKey_sr}
          />

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="Product Rating"
                name="product_rating_id"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                // className={`${styles.select_styles}`}
                className={`${styles.input_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceProduct_rating}
                  placeholder="Click to select"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('product_rating_id', value)
                  }
                >
                  {dropDown_product_ratingData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>

          <div>
            <Radio.Group
              value={selectedTabId}
              size="middle"
              optionType="button"
              buttonStyle="solid"
              className={`${styles.tab_btn_style}`}
            >
              <Space direction={'horizonal'} size={4} style={{ width: '100%' }}>
                {filters?.map((item, id) => {
                  return (
                    <Radio.Button
                      key={id}
                      name="radiogroup"
                      defaultValue={item[0]?.value}
                      value={item?.id}
                      //onChange={() => setRadiofilter(item.id)}
                      className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                      onClick={() => {
                        setSelectedTabId(item?.id);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Space>
            </Radio.Group>

            <Divider
              style={{ margin: 0, marginBottom: '20px', background: '#d0d5dd' }}
            ></Divider>
          </div>
          {selectedTabId === 1 && (
            <>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    USTableError.base
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case US - Best Case{' '}
                        <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span>
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustificationUS_BaseCase
                      data={dataSource_US_BaseCase}
                      setError={setUSTableError}
                      error={USTableError}
                      setData={setDataSource_US_BaseCase}
                      selectedYear={selectedYear_US_BaseCase}
                      setSelectedYear={setSelectedYear_US_BaseCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    USTableError.best
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case US - Best Case{' '}
                        <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span>
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustificationUS_BestCase
                      data={dataSource_US_BestCase}
                      setError={setUSTableError}
                      error={USTableError}
                      setData={setDataSource_US_BestCase}
                      selectedYear={selectedYear_US_BestCase}
                      setSelectedYear={setSelectedYear_US_BestCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Financial Performance Metrics US{' '}
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <FinancialPerformanceMetricsUS
                      dataSource_FPM_US={dataSource_FPM_US}
                      setDataSource_FPM_US={setDataSource_FPM_US}
                      generateKey_fpm_us={generateKey_fpm_us}
                      bj_edit={bj_edit}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                      dropDown_fpm_itemsData={dropDown_fpm_itemsData}
                      debounceFpm_items={debounceFpm_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
            </>
          )}
          {selectedTabId === 2 && (
            <>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    EUTableError.base
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case EU - Best Case{' '}
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustification_EU_BaseCase
                      data={dataSource_EU_BaseCase}
                      setError={setEUTableError}
                      error={EUTableError}
                      setData={setDataSource_EU_BaseCase}
                      selectedYear={selectedYear_EU_BaseCase}
                      setSelectedYear={setSelectedYear_EU_BaseCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    EUTableError.best
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case EU - Best Case{' '}
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustification_EU_BestCase
                      data={dataSource_EU_BestCase}
                      setError={setEUTableError}
                      error={EUTableError}
                      setData={setDataSource_EU_BestCase}
                      selectedYear={selectedYear_EU_BestCase}
                      setSelectedYear={setSelectedYear_EU_BestCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Financial Performance Metrics EU{' '}
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <FinancialPerformanceMetrics_EU
                      dataSource_FPM_US={dataSource_FPM_EU}
                      setDataSource_FPM_US={setDataSource_FPM_EU}
                      generateKey_fpm_us={generateKey_fpm_eu}
                      bj_edit={bj_edit}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                      dropDown_fpm_itemsData={dropDown_fpm_itemsData}
                      debounceFpm_items={debounceFpm_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
            </>
          )}
          {selectedTabId === 3 && (
            <>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    RoWTableError.base
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case RoW - Best Case{' '}
                        {/*  <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustification_RoW_BaseCase
                      data={dataSource_RoW_BaseCase}
                      setError={setRoWTableError}
                      error={RoWTableError}
                      setData={setDataSource_RoW_BaseCase}
                      selectedYear={selectedYear_RoW_BaseCase}
                      setSelectedYear={setSelectedYear_RoW_BaseCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                  style={
                    RoWTableError.best
                      ? {
                          border: '1px solid red',
                          backgroundColor: '#f546460d',
                          borderRadius: '0.5rem'
                        }
                      : {}
                  }
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Business Case RoW - Best Case
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <BusinessJustification_RoW_BestCase
                      data={dataSource_RoW_BestCase}
                      setError={setRoWTableError}
                      error={RoWTableError}
                      setData={setDataSource_RoW_BestCase}
                      selectedYear={selectedYear_RoW_BestCase}
                      setSelectedYear={setSelectedYear_RoW_BestCase}
                      dropDown_fy_mastersData={dropDown_fy_mastersData}
                      dropDown_bjc_forecast_itemsData={
                        dropDown_bjc_forecast_itemsData
                      }
                      debounceBjc_forecast_items={debounceBjc_forecast_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
              <Row gutter={16} className={`${styles.ip_table_container}`}>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <span style={{ fontWeight: 500, fontSize: '14px' }}>
                        Financial Performance Metrics RoW
                        {/* <span style={{ color: 'red', fontSize: '16px' }}>
                          *
                        </span> */}
                      </span>
                    }
                    //extra="Add email message"
                    key="2"
                  >
                    <Divider style={{ marginTop: '0px' }} />

                    <FinancialPerformanceMetrics_RoW
                      dataSource_FPM_US={dataSource_FPM_RoW}
                      setDataSource_FPM_US={setDataSource_FPM_RoW}
                      generateKey_fpm_us={generateKey_fpm_row}
                      bj_edit={bj_edit}
                      dropDown_budget_itemsData={dropDown_budget_itemsData}
                      dropDown_fpm_itemsData={dropDown_fpm_itemsData}
                      debounceFpm_items={debounceFpm_items}
                    />
                  </Panel>
                </CustomCollapse>
              </Row>
            </>
          )}
          <Divider
            style={{ margin: 0, marginBottom: '20px', background: '#d0d5dd' }}
          ></Divider>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    Global Financial Performance Metrics
                    {/* <span style={{ color: 'red', fontSize: '16px' }}>*</span> */}
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />

                <GlobalFinancialPerformanceMetrics_table
                  setDataSource_GFPM={setDataSource_GFPM}
                  dataSource_GFPM={dataSource_GFPM}
                  generateKey_gfpm={generateKey_gfpm}
                  bj_edit={bj_edit}
                  //dropDown_budget_itemsData={dropDown_budget_itemsData}
                  dropDown_gfpm_itemsData={dropDown_gfpm_itemsData}
                  debounceGfpm_items={debounceGfpm_items}
                  //getFormDetailsByIdData={getFormDetailsByIdData}
                />
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('Remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>

            <FormItem>
              <CustomButton
                loading={createUpdateBJ_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Business_justification;
